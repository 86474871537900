import React, { useEffect, useState } from 'react';
import './AddonSelection.css'; // Import the CSS file

const AddonSelection = ({onBack}) => {
  const [addons, setAddons] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [loading, setLoading] = useState(true); // New state for loading

  // Fetch the product addons from the API
  useEffect(() => {
    const fetchAddons = async () => {
      const formdata = new FormData();
      formdata.append("action", "getproductaddon");
      formdata.append("api_key", "test");

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };

      try {
        const response = await fetch("https://billing.kreatebiz.com/includes/sitegenerationapi.php", requestOptions);
        const result = await response.json();

        if (result.result === "success") {
          setAddons(result.addons); // Set the fetched addons to state
        } else {
          console.error('Error fetching addons:', result.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchAddons();
  }, []);

  // Handle checkbox change
  const handleCheckboxChange = (addonId) => {
    setSelectedAddons(prevSelected => {
      if (prevSelected.includes(addonId)) {
        return prevSelected.filter(id => id !== addonId); // Remove addon
      } else {
        return [...prevSelected, addonId]; // Add addon
      }
    });
  };

  // Handle Add to Cart button click
  const handleAddToCart = async () => {
    if (selectedAddons.length === 0) {
      alert('Please select at least one addon.');
      return;
    }

    const formdata = new FormData();
    formdata.append("action", "addontocart");
    formdata.append("api_key", "test");
    formdata.append("addonIds", selectedAddons.join(',')); // Join selected addon IDs

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };

    try {
      const response = await fetch("https://billing.kreatebiz.com/includes/sitegenerationapi.php", requestOptions);
      const result = await response.json();

      if (result.result === "success") {
        alert('Addons added to cart successfully!');
      } else {
        console.error('Error adding addons to cart:', result.message);
        alert('Failed to add addons to cart.');
      }
    } catch (error) {
      console.error('Error adding to cart:', error);
      alert('Error occurred while adding addons to cart.');
    }
  };

  return (
    <div className="addon-selection-container">
      <h3>Select Addons</h3>
      
      {loading ? (
        <div className="loader">Loading...</div> // Show loader while fetching
      ) : (
        <form className="addon-form">
          {addons.map(addon => (
            <div key={addon.id} className="addon-item">
              <label>
                <input
                  type="checkbox"
                  value={addon.id}
                  onChange={() => handleCheckboxChange(addon.id)}
                />
                <span className="addon-name">{addon.name}</span>
              </label>
              <p className="addon-description">{addon.description}</p>

              {/* Pricing Section */}
              {addon.pricing && addon.pricing.length > 0 && (
                <div className="pricing">
                  {addon.pricing.map((price, index) => (
                    <div key={index} className="pricing-item">
                      <span className="price">
                        ${price.price}
                      </span>
                      <span className="billing-cycle">
                        {price.billingcycle}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </form>
      )}

      <div className="selected-addons">
        <h4>Selected Addons:</h4>
        <ul>
          {selectedAddons.map(id => {
            const addon = addons.find(addon => addon.id === id);
            return <li key={id}>{addon?.name}</li>;
          })}
        </ul>
      </div>
      <button className="add-to-cart-button" onClick={()=>onBack()}>Back</button>
      <button className="add-to-cart-button" onClick={handleAddToCart}>Add to Cart</button>
    </div>
  );
};

export default AddonSelection;
