import React, { useState,useEffect } from 'react';
import { ImageSelection,Modal,OrganizationForm,Preview,Service,TemplateSelection,DomainSelection,AddonSelection } from './components';

const App = ({appData}) => {
    const [view, setView] = useState('template'); // Initial view is the form
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({
        organisationName: '',
        organisationCategory: '',
        organisationSubcategory:'',
        email: '',
        phone: '',
        address:'',
        content: '',
        city: '',
        country: '',
        pincode:'',
        selectedImages:[],
        aboutYourself:'',
        selectedTemplate:'',
        services:[
            { name: '', description: '', loading: false }
        ],
        templates: [] ,
        appData:appData

    });

    useEffect(() => {
        const fetchTemplates = async () => {
          setIsLoading(true); // Set loading to true when starting to fetch
          try {
            const myHeaders = new Headers();
            const formdata = new FormData();
            formdata.append("action", "gettemplate");
            formdata.append("api_key", "test");
            const requestOptions = {
                method: "POST",
                body: formdata,
                redirect: "follow"
            };
            fetch("https://billing.kreatebiz.com/includes/sitegenerationapi.php", requestOptions)
            .then((response) => response.json())
            .then((result) =>  setFormData({
                ...formData,
                templates: result.content,
            })      
            )

          } catch (error) {
            console.error('Error fetching templates:', error);
          } finally {
            setIsLoading(false); // Set loading to false after fetching
          }
        };
    
        fetchTemplates();
      }, []);

    


    const renderView = () => {
        switch (view) {
            case 'template':
                return <TemplateSelection formData={formData} onNext={() => setView('form')} setFormData={(value) => setFormData(value)} />;
            case 'form':
                return <OrganizationForm onBack={() => setView('template')} onNext={() => setView('service')} formData={formData} setFormData={(value) => setFormData(value)} />;
            case 'service':
                return <Service onNext={() => setView('image')} onBack={() => setView('form')} formData={formData} setFormData={(value) => setFormData(value)} />;
            case 'image':
                return <ImageSelection onNext={() => setView('preview')} onBack={() => setView('service')} formData={formData} setFormData={(value) => setFormData(value)} />;     
            case 'preview':
                    return <Preview  onBack={() => setView('image')} formData={formData} onNext={() => setView('domain')}   setFormData={(value) => setFormData(value)}/>;   
            case 'domain':
                    return <DomainSelection  onBack={() => setView('preview')}  onNext={() => setView('addon')}   formData={formData}  setFormData={(value) => setFormData(value)}/>;   
            case 'addon':
                    return <AddonSelection  onBack={() => setView('domain')}  formData={formData}  setFormData={(value) => setFormData(value)}/>;   
            default:
                return <OrganizationForm onNext={() => setView('image')} />;
        }
    };
    return (
        <div>
            {renderView()}
        </div>
    );
};

export default App;
