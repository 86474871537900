import React, { useState, useEffect } from 'react';
import './ImageSelection.css';

const ImageSelection = ({ onNext, setFormData, formData, onBack }) => {
    const [images, setImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);  // Store selected images
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async () => {
        try {
            setLoading(true);
            const myHeaders = new Headers();
            const formdata = new FormData();
            formdata.append("action", "getimages");
            formdata.append("api_key", "test");
            formdata.append("query", formData.organisationCategory);
            const requestOptions = {
                method: "POST",
                body: formdata,
                redirect: "follow"
            };
            const response = await fetch("https://billing.kreatebiz.com/includes/sitegenerationapi.php", requestOptions);           
            const data = await response.json();
            setImages(data);
            setPage(page + 1);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    const handleImageClick = (id, fullImageUrl) => {
        // Check if the image is already selected
        const isImageSelected = selectedImages.some(image => image.id === id);
    
        if (isImageSelected) {
            // Remove the image from the selected images array
            setSelectedImages(prevImages => prevImages.filter(image => image.id !== id));
        } else if (selectedImages.length < 3) {
            // Add the image to the selected images array if there are less than 3 images
            setSelectedImages(prevImages => [...prevImages, { id, fullImageUrl }]);
        } else {
            // Optionally, show a message if trying to select more than 3 images
            alert('You can only select up to 3 images.');
        }
    };

    const handleRemoveImage = (id) => {
        setSelectedImages(prevImages => prevImages.filter(image => image.id !== id));
    };

    const handleNextClick = () => {
        // Set selected images URLs into formData
        setFormData({
            ...formData,
            selectedImages: selectedImages.map(image => image.fullImageUrl),
        });
        onNext();
    };

    return (
        <div className="image-selection">
            <h2>Select Images</h2>
            <div className="selected-images">
                {selectedImages.map((image) => (
                    <div key={image.id} className="selected-image">
                        <img src={image.fullImageUrl} alt={`Selected ${image.id}`} className="selected-image-thumbnail" />
                        <button onClick={() => handleRemoveImage(image.id)} className="remove-button">X</button>
                    </div>
                ))}
            </div>
            <div className="image-container">
                {loading ? (
                    <p>Loading images...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    images.map((image) => (
                        <div
                            key={image.id}
                            onClick={() => handleImageClick(image.id, image.full_image_url)}
                            className={`image-card ${selectedImages.some(selected => selected.id === image.id) ? 'selected' : ''}`}
                        >
                            <img
                                src={image.small_image_url}
                                alt={image.alt_description || `Image ${image.id}`}
                                className="image"
                            />
                        </div>
                    ))
                )}
            </div>

        

            <button onClick={fetchImages} className="next-button">
                Regenerate
            </button>
            <button onClick={onBack} className="next-button">
                Back
            </button>
            <button
    onClick={handleNextClick}
    className="next-button"
    disabled={selectedImages.length === 0 || selectedImages.length > 3}  // Disable button if more than 3 images are selected
>
    Next
</button>
        </div>
    );
};

export default ImageSelection;
