import React, { useState, useEffect } from 'react';
import './DomainSelection.css'; // Importing the CSS file for styling

const DomainOptions = ({ onNext }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [domain, setDomain] = useState('');
  const [tld, setTld] = useState('.com');
  const [tldOptions, setTldOptions] = useState([]);
  const [availabilityMessage, setAvailabilityMessage] = useState('');
  const [isAvailable, setIsAvailable] = useState(false); // To track availability
  const [loading, setLoading] = useState(true); // To track loading state for TLDs
  const [domainCheckLoading, setDomainCheckLoading] = useState(false); // To track loading state for domain checking

  // Dropdown for base domains
  const [baseDomain, setBaseDomain] = useState('kreatebiz.com'); // Default base domain
  const baseDomainOptions = ['kreatebiz.com', 'mysite.net', 'example.org']; // Add your base domains here

  useEffect(() => {
    const fetchTlds = () => {
      const myHeaders = new Headers();

      const formdata = new FormData();
      formdata.append('action', 'gettlds');
      formdata.append('api_key', 'test'); // Your API key
      formdata.append('domain', 'examples.com');

      const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
      };

      fetch(
        'https://billing.kreatebiz.com/includes/sitegenerationapi.php',
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const response = JSON.parse(result);
          if (response.status === 'success') {
            setTldOptions(response.data); // Set the TLD options from the response
          } else {
            setAvailabilityMessage('Error fetching TLDs.');
          }
        })
        .catch((error) => {
          console.error('Error fetching TLDs:', error);
          setAvailabilityMessage('Error fetching TLDs.');
        })
        .finally(() => {
          setLoading(false); // Stop loading after fetch completes
        });
    };

    fetchTlds();
  }, []); // Run only once when the component mounts

  const handleCheckDomain = () => {
    if (!domain) {
      setAvailabilityMessage('Please enter a domain name.');
      setIsAvailable(false);
      return;
    }
    setDomainCheckLoading(true);
    const fullDomain = domain + tld;

    const formdata = new FormData();
    formdata.append('action', 'checkdomainavailability');
    formdata.append('api_key', 'test');
    formdata.append('domain', fullDomain);

    const requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };

    fetch(
      'https://billing.kreatebiz.com/includes/sitegenerationapi.php',
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        const response = JSON.parse(result);
        if (response.available) {
          setAvailabilityMessage(response.message);
          setIsAvailable(true);
        } else {
          setAvailabilityMessage(response.message);
          setIsAvailable(false);
        }
        setDomainCheckLoading(false);
      })
      .catch((error) => {
        console.error('Error checking domain:', error);
        setAvailabilityMessage('Error checking domain availability.');
        setIsAvailable(false);
        setDomainCheckLoading(false);
      });
  };

  const selectOption = (value) => {
    setIsAvailable(false);
    setAvailabilityMessage('');
    setSelectedOption(value);
  };

  const handleDomainRegister = () => {
    let fullDomain = domain;
    if (!domain) {
      setAvailabilityMessage('Please enter a domain name.');
      setIsAvailable(false);
      return;
    }
    if (selectedOption === 'register') {
      fullDomain = domain + tld;
    } else if (selectedOption === 'subdomain') {
      fullDomain = `${domain}.${baseDomain}`;
    }

    const formdata = new FormData();
    formdata.append('action', 'adddomaintocart');
    formdata.append('api_key', 'test');
    formdata.append('domain', fullDomain);
    formdata.append('domainAction', selectedOption);
    formdata.append('year', 1);

    const requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };

    fetch(
      'https://billing.kreatebiz.com/includes/sitegenerationapi.php',
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        const response = JSON.parse(result);
        if (response.result === 'success') {
          onNext();
        }
      })
      .catch((error) => {
        console.error('Error checking domain:', error);
      });
  };

  return (
    <div className="domain-container">
      <h2>Choose Domain Option</h2>
      <div className="radio-buttons">
        <label>
          <input
            type="radio"
            name="domainOption"
            value="subdomain"
            checked={selectedOption === 'subdomain'}
            onChange={(e) => selectOption(e.target.value)}
          />
          Select Subdomain
        </label>
        <label>
          <input
            type="radio"
            name="domainOption"
            value="existing"
            checked={selectedOption === 'existing'}
            onChange={(e) => selectOption(e.target.value)}
          />
          Use Existing Domain
        </label>
        <label>
          <input
            type="radio"
            name="domainOption"
            value="register"
            checked={selectedOption === 'register'}
            onChange={(e) => selectOption(e.target.value)}
          />
          Buy Domain
        </label>
      </div>

      <div className="input-fields">
        {/* Subdomain Input */}
        <div
          className={`input-container ${
            selectedOption === 'subdomain' ? 'active' : ''
          }`}
        >
          <label>
            Enter Subdomain:
            <input
              type="text"
              placeholder="Enter subdomain"
              onChange={(e) => setDomain(e.target.value)}
            />
          </label>
          <label>
            Select Base Domain:
            <select
              value={baseDomain}
              onChange={(e) => setBaseDomain(e.target.value)}
            >
              {baseDomainOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </label>
          {domain && (
            <p className="preview">
              Preview: {domain}.{baseDomain}
            </p>
          )}
        </div>

        {/* Existing Domain Input */}
        <div
          className={`input-container ${
            selectedOption === 'existing' ? 'active' : ''
          }`}
        >
          <label>
            Enter Existing Domain:
            <input
              type="text"
              placeholder="Enter existing domain"
              onChange={(e) => setDomain(e.target.value)}
            />
          </label>
        </div>

        {/* Buy Domain Input */}
        <div
          className={`input-container ${
            selectedOption === 'register' ? 'active' : ''
          }`}
        >
          <label>
            Enter Domain Name:
            <input
              type="text"
              placeholder="Enter domain name"
              onChange={(e) => setDomain(e.target.value)}
            />
          </label>
          <label>
            Select TLD:
            {loading ? (
              <span>Loading TLDs...</span>
            ) : (
              <select value={tld} onChange={(e) => setTld(e.target.value)}>
                {tldOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            )}
          </label>
          <div className="check-btn-container">
            <button
              className="check-btn"
              onClick={handleCheckDomain}
              disabled={domainCheckLoading}
            >
              {domainCheckLoading ? 'Checking...' : 'Check Availability'}
            </button>
          </div>
        </div>
      </div>

      {availabilityMessage && (
        <div
          className={`availability-message ${
            isAvailable ? 'available' : 'not-available'
          }`}
        >
          <p>{availabilityMessage}</p>
        </div>
      )}

      {(selectedOption === 'subdomain' ||
        selectedOption === 'existing' ||
        (selectedOption === 'register' && isAvailable)) && (
        <div className="next-btn-container">
          <button className="next-btn" onClick={handleDomainRegister}>
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default DomainOptions;
