import React from 'react';
import './Modal.css'; // You can style your modal here

const Modal = ({ isOpen, onRequestClose, children,isMobilePreview }) => {
    if (!isOpen) return null; // Don't render if not open
    const modalContentClass = isMobilePreview ? 'modal-content mobile-preview' : 'modal-content web-preview';

    return (
        <div className="modal-overlay" onClick={onRequestClose}>
            <div className={modalContentClass} onClick={(e) => e.stopPropagation()}>
                <button className="modal-close" onClick={onRequestClose}>X</button>
                {children}
            </div>
        </div>
    );
};

export default Modal;