const initialState = {
    myData: null,
  };

  const rootReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'MY_ACTION':
        return {
          ...state,
          myData: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default rootReducer;