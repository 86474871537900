import React, { useState } from 'react';
import './OrganizationForm.css'; // Import the CSS file
import axios from "axios";
import countriesData from '../../country.json';

const OrganizationForm = ({ onNext, formData, setFormData,onBack }) => {

    const [formPart, setFormPart] = useState(1)
    const [loading,setLoading] = useState(false)
    const [subcategories, setSubcategories] = useState([]);
    const [errors, setErrors] = useState({});
    const fetchContent = async() => {
        setLoading(true)
        try {
            const myHeaders = new Headers();
            const formdata = new FormData();
            formdata.append("action", "getaboutcontent");
            formdata.append("api_key", "test");
            formdata.append("organization_name", formData.organisationName);
            formdata.append("category", formData.organisationCategory);
            formdata.append("subcategory", formData.organisationSubcategory);
            formdata.append("description", formData.aboutYourself);
            const requestOptions = {
                method: "POST",
                body: formdata,
                redirect: "follow"
            };
            fetch("https://billing.kreatebiz.com/includes/sitegenerationapi.php", requestOptions)
            .then((response) => response.json())
            .then((result) =>  { setFormData({
                ...formData,
                ['organisationTextArea']: result?.content,
            });
            setLoading(false)}
            )
          
        } catch (error) {
            console.log('Error: ' + error.message);
            setLoading(false)
        }
    }


    const handleSubmit = (event) => {
        if (validateForm()) {
            if (formPart === 2) {
              onNext();
            } else {
              setFormPart(formPart + 1);
            }
          }
    };


    const handleBack = () => {
        if(formPart != 2){
            onBack();
        }
        else{
            setFormPart(formPart-1)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        if (name === "organisationCategory") {
            // Update subcategories based on the selected category
            updateSubcategories(value);
        }

        if (name === "pincode") {
            handlePincodeChange(formData?.country,value);
        }
    };

    const handlePincodeChange = async (code,pincode) => {
        if ( pincode.length <= 6) { 
            try {
                const response = await axios.get(`https://api.zippopotam.us/${code}/${pincode}`);
                if (response.data) {
                    const place = response.data.places[0];
                    setFormData((prev) => ({
                        ...prev,
                        city: place["place name"]
                    }));
                }
            } catch (error) {
                if (error.response) {
                    if (error.response.status === 404) {
                      console.log('Location not found. Please check the pincode and try again.');
                    } else {
                      console.log(`Error: ${error.response.status} - ${error.response.statusText}`);
                    }
                  } else {
                    console.log('An error occurred. Please try again later.');
                  }
            }
        }
        else {
            setFormData((prev) => ({
                ...prev,
                city: "",
                country: ""
            }));
        }
    };

    // Validation function to check for errors
  const validateForm = () => {
    const newErrors = {};
    // Check if required fields are filled
    if (!formData.organisationName) {
      newErrors.organisationName = 'Organisation name is required';
    }
    if (!formData.organisationCategory) {
      newErrors.organisationCategory = 'Organisation category is required';
    }
    // if (!formData.organisationSubcategory) {
    //   newErrors.organisationSubcategory = 'Organisation subcategory is required';
    // }
    if (!formData.phone) {
       newErrors.phone = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.phone)) {
       newErrors.phone = 'Phone number must be 10 digits';
     }
     if (!formData.email) {
      newErrors.email = 'Email is required';
     } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
       newErrors.email = 'Email is invalid';
     }
    // if (!formData.country) {
    //   newErrors.country = 'Country is required';
    // }
    // if (formData.pincode && !/^\d{6}$/.test(formData.pincode)) {
    //   newErrors.pincode = 'Pincode must be 6 digits';
    // }
    // if (formData.aboutYourself && formData.aboutYourself.length < 20) {
    //   newErrors.aboutYourself = 'Please provide more details about yourself (min 20 characters)';
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


    const updateSubcategories = (category) => {
        let options = [];
        switch (category) {
            case "Bakery":
                options = ["Bread", "Cakes", "Pastries"];
                break;
            case "Educational":
                options = ["School", "College", "University"];
                break;
            case "Restaurant":
                options = ["Fast Food", "Fine Dining", "Cafe"];
                break;
            default:
                options = [];
        }
        setSubcategories(options);
    };

    const renderFrom = () => {
        if(formPart == 2){
            return (
                <>
                <div className="form-group">
                    <label htmlFor="aboutYourself">
                        Tell me about yourself:
                        <textarea
                            name="aboutYourself"
                            id="aboutYourself"
                            value={formData.aboutYourself}
                            onChange={handleChange}
                            rows="5"
                            placeholder="Enter information about yourself here..."
                        />
                    </label>
                    {errors.aboutYourself && <span className='organization-form-error'>{errors.aboutYourself}</span>}
                </div>
                <div className="form-group">
                    <label htmlFor="organisationTextArea">
                        Organisation Content:
                        <textarea
                            name="organisationTextArea"
                            id="organisationTextArea"
                            value={formData.organisationTextArea}
                            onChange={handleChange}
                            rows="10"
                            placeholder="Enter the organisation content here..."
                        />
                    </label>
                    <button onClick={fetchContent} disabled={loading}>
                        {loading ? 'Generating...' : 'Generate with AI'}
                    </button>
                </div>
                </>
            );
            
        }
        return (
            <div>
                <h2>Organisation Registration</h2>
                <div className="form-group">
                    <label>
                        Organisation Name:
                        <input
                            type="text"
                            name="organisationName"
                            value={formData.organisationName}
                            onChange={handleChange}
                        />
                    </label>
                    {errors.organisationName && <span className='organization-form-error'>{errors.organisationName}</span>}
                </div>
                <div className="form-group">
                    <label htmlFor="organisationCategory">
                        Organisation Category:
                        <select
                            name="organisationCategory"
                            id="organisationCategory"
                            value={formData.organisationCategory}
                            onChange={handleChange}
                        >
                            <option value="">Select a category</option>
                            <option value="Bakery">Bakery</option>
                            <option value="Educational">Educational</option>
                            <option value="Restaurant">Restaurant</option>

                        </select>          
                    </label>
                    {errors.organisationCategory && <span className='organization-form-error'>{errors.organisationCategory}</span>}
                </div>
                {subcategories?.length > 0 && (
                <div className="form-group">
                    <label htmlFor="organisationSubcategory">
                        Subcategory:
                        <select
                            name="organisationSubcategory"
                            id="organisationSubcategory"
                            value={formData.organisationSubcategory}
                            onChange={handleChange}
                        >
                            <option value="">Select a subcategory</option>
                            {subcategories.map((subcat, index) => (
                                <option key={index} value={subcat}>
                                    {subcat}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>
            )}

<div className="form-group">
                <label htmlFor="phone">Phone:</label>
                <input
                    type="tel"
                    name="phone"
                    id="phone"
                    value={formData.phone}
                    onChange={handleChange}
                />
                {errors.phone && <span className='organization-form-error'>{errors.phone}</span>}
            </div>

            <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                />
                {errors.email && <span className='organization-form-error'>{errors.email}</span>}
            </div>

                    <div className="form-group">
            <label htmlFor="country">Country:</label>
            <select
                name="country"
                id="country"
                value={formData.country}
                onChange={handleChange}
            >
                <option value="">--Select a Country--</option>
                {countriesData.map((country) => (
                    <option key={country.code} value={country.code}>
                        {country.country}
                    </option>
                ))}
            </select>
            {errors.country && <span>{errors.country}</span>}
        </div>
        {formData?.country &&
        <>
            <div className="form-group">
                <label htmlFor="pincode">Pincode:</label>
                <input
                    type="text"
                    name="pincode"
                    id="pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                />
                {errors.pincode && <span>{errors.pincode}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="address">Address:</label>
                <input
                    type="text"
                    name="address"
                    id="address"
                    value={formData.address}
                    onChange={handleChange}
                />
            </div>

            <div className="form-group">
                <label htmlFor="city">City:</label>
                <input
                    type="text"
                    name="city"
                    id="city"
                    value={formData.city}
                    onChange={handleChange}
                />
            </div>
       
    
        
            </>
        }
            </div>
        )
    }

    return (
        <div className="organisation-form">
            {renderFrom()}
            <button className="submit-button" onClick={handleBack}>Back</button>
            <button className="submit-button" onClick={handleSubmit}>Next</button>
        </div>
    );
};

export default OrganizationForm;
