import React, { useState,useEffect,useRef } from 'react';
import Modal from '../Modal/Modal'; // Reusable Modal Component
import './Preview.css';
import { FaMobileAlt,FaDesktop  } from 'react-icons/fa';
import {updateIframeText} from './UpdatePreviewData'

const Preview = ({ onNext, formData, onBack,setFormData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobilePreview, setIsMobilePreview] = useState(false);
  const [error, setError] = useState(null);  
  const [loading, setLoading] = useState(true);
  const [siteLoading,setSiteLoading] = useState(false)
  const iframeRef = useRef(null);
console.log(formData)
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        setLoading(true);  
        const myHeaders = new Headers();
        const formdata = new FormData();
        formdata.append("action", "getaddionaldata");
        formdata.append("api_key", "test");
        formdata.append("organization_name", formData.organisationName);
        formdata.append("category", formData.organisationCategory);
        formdata.append("subcategory", formData.organisationSubcategory);
        formdata.append("description", formData.aboutYourself);
        const requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow"
        };
        fetch("https://billing.kreatebiz.com/includes/sitegenerationapi.php", requestOptions)
        .then((response) => response.json())
        .then((result) =>   setFormData({
            ...formData,
            ['additionalData']: result,
        }
      )).then(()=>{ setLoading(false); })
        
      } catch (error) {
        setError(error.message);  
        setLoading(false); 
      } 
    };

    fetchData();  // Call the fetch function when the component mounts

  }, []);

  const handleNextClick = () => {

    handleSubmit();
    onNext();

};

  const handleSubmit = async () => {

  
    setSiteLoading(true)
    try {
      const myHeaders = new Headers();
      const formdata = new FormData();
      formdata.append("action", "addtocart");
      formdata.append("api_key", "test");
      formdata.append("formData", JSON.stringify(formData));
      const requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow"
      };
      const response = await fetch("https://billing.kreatebiz.com/includes/sitegenerationapi.php", requestOptions);           
      const data = await response.json();
        console.log(data)
        setSiteLoading(false)
        // setMessage(data); // Handle the response from the server
    } catch (error) {
        console.log('Error: ' + error.message);
        setSiteLoading(false)
    }
};
  // Toggle the modal open/close
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Set the preview type (web or mobile)
  const openMobilePreview = () => {
    setIsMobilePreview(true);
    toggleModal();
  };

  const openWebPreview = () => {
    setIsMobilePreview(false);
    toggleModal();
  };

  const handleModalIframeLoad = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      const iframeDocument = iframe.contentWindow.document;
      updateIframeText(iframeDocument, formData);  // Pass the iframe document to the function
    }
  };

  // Preview URL
  const previewUrl = formData?.selectedTemplate?.template_url;


  if (loading) {
    return <div>Loading...</div>; 
  }

  return (
    <>
    <div className='preview-buttons'>
      <button
        className="preview-button mobile-preview"
        onClick={openMobilePreview}
      >
       <FaMobileAlt
       size={70}
       color='#3ec3eb'
       />
      </button>
      <button
        className="preview-button web-preview"
        onClick={openWebPreview}
      >
            <FaDesktop
       size={70}
       color='#27da76'
       />
      </button>

      {isModalOpen && (
        <Modal
    
          isOpen={isModalOpen}
          onRequestClose={toggleModal}
          contentLabel="Template Modal"
          isMobilePreview={isMobilePreview}
        >
          <h2>{isMobilePreview ? "Mobile" : "Web"} Preview</h2>
          <iframe 
            ref={iframeRef}
            src={`${previewUrl}/?t=${new Date().getTime()}`}
            width="100%"
            height="500px"
            title="Template Preview"
            frameBorder="0"
            onLoad={handleModalIframeLoad}
          />
        </Modal>
      )}
    </div>

    <button onClick={onBack}>Back</button>
    <button onClick={handleNextClick} className="next-button" disabled={siteLoading}> 
    {siteLoading ? "Processing..." : "Next" }
    </button>
    </>
  );
};

export default Preview;
