export const updateIframeText = (iframeDocument, formData) => {
  // Safely access elements in the iframe document
  const sitenameElements = iframeDocument.querySelectorAll('.kreatebiz_sitename');
  sitenameElements.forEach(element => {
    element.textContent = formData.organisationName;
  });

  const aboutContentElements = iframeDocument.querySelectorAll('.kreatebiz_about_content');
  aboutContentElements.forEach(element => {
    element.textContent = formData.organisationTextArea;
  });

  const addressElement = iframeDocument.querySelector('.kreatebiz_address');
  const cityElement = iframeDocument.querySelector('.kreatebiz_city');
  const pincodeElement = iframeDocument.querySelector('.kreatebiz_pincode');
  const emailElement = iframeDocument.querySelector('.kreatebiz_email');
  const phoneElement = iframeDocument.querySelector('.kreatebiz_phone');
  const countryElement = iframeDocument.querySelector('.kreatebiz_country');
  const heroHeadingElement1 = iframeDocument.querySelector('.kreatebiz_heading1');
  const heroHeadingElement2 = iframeDocument.querySelector('.kreatebiz_heading2');
  const aboutHeading = iframeDocument.querySelector('.kreatebiz_about_heading');

  // Set text content for other elements
  if (addressElement) addressElement.textContent = formData.address;
  if (cityElement) cityElement.textContent = formData.city;
  if (pincodeElement) pincodeElement.textContent = formData.pincode;
  if (emailElement) emailElement.textContent = formData.email;
  if (phoneElement) phoneElement.textContent = formData.phone;
  if (countryElement) countryElement.textContent = formData.country;
  if (heroHeadingElement1) heroHeadingElement1.textContent = formData?.additionalData?.hero_heading;
  if (heroHeadingElement2) heroHeadingElement2.textContent = formData?.additionalData?.hero_subheading;
  if (aboutHeading) aboutHeading.textContent = formData?.additionalData?.about_heading;

  // Handle image update
  const imageOneElements = iframeDocument.querySelectorAll('.kreatebiz_image_1');
  if (imageOneElements.length > 0 && formData?.selectedImages?.length > 0) {
    imageOneElements[0].src = formData?.selectedImages[0];
  }

  // Handle services section
  const servicesSection = iframeDocument.querySelector('.kreatebiz_services');
  if (formData?.services && formData.services.length === 0) {
    // If services array is empty, remove the services section
    if (servicesSection) {
      servicesSection.remove();
    }
  } else if (formData?.services && formData.services.length >= 1) {
    const serviceRow = iframeDocument.querySelector('.kreatebiz_service_row');

    if (serviceRow) {
      const serviceCloneTemplate = serviceRow.querySelector('.kreatebiz_service').cloneNode(true);
      const existingServices = serviceRow.querySelectorAll('.kreatebiz_service');
      existingServices.forEach(service => service.remove());

      formData.services.forEach(service => {
        const serviceClone = serviceCloneTemplate.cloneNode(true);
        const headingElement = serviceClone.querySelector('.kreatebiz_heading');
        const contentElement = serviceClone.querySelector('.kreatebiz_content');

        if (headingElement) headingElement.textContent = service.name;
        if (contentElement) contentElement.textContent = service.description;

        serviceRow.appendChild(serviceClone);
      });
    }
  }
};
