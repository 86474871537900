import React, { useState } from 'react';
import './TemplateSelection.css'; 

  
  const TemplateSelection = ({onNext,formData,setFormData}) => {
    const handleTemplateSelect = (item) => {
      console.log(item)
        setFormData((prev) => ({
            ...prev,
            'selectedTemplate': item
        }));
    };
    return (
      <div className="template-selector-container">
        <h2 className="template-selector-title">Select a Template</h2>
  
        <div className="template-images">
          {formData?.templates.map(item => {
            console.log(item)
            return(
            <div 
              key={item.temp_id}
              className={`template-image ${formData?.selectedTemplate?.template_image_url === item.template_image_url ? 'selected' : ''}`}
              onClick={() => handleTemplateSelect(item)}
            >
              <img src={item.template_image_url}  />
            </div>
            )
          }
        )}
        </div>
  
        {formData?.selectedTemplate && (
          <button className="next-button" onClick={onNext}>
            Next
          </button>
        )}
      </div>
    );
  };

export default TemplateSelection;
