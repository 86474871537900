import React, { useState } from 'react';
import './Service.css';

const Service = ({onNext,formData,setFormData,onBack}) => {
    const [services, setServices] = useState(formData.services);

    // Handler to add a new service
    const addService = () => {
        setServices([...services, { name: '', description: '', loading: false }]);
    };

    // Handler to remove a service
    const removeService = (index) => {
        setServices(services.filter((_, i) => i !== index));
    };

    const handleService = () => {
        setFormData({
            ...formData,
            'services': services,
        });
        onNext()
    }

    // Handler to update multiple fields in a service
    const updateServiceFields = (index, fields) => {
        const updatedServices = services.map((service, i) =>
            i === index ? { ...service, ...fields } : service
        );
        setServices(updatedServices);
    };

    // Placeholder AI function to generate description
    const generateDescription = async (serviceName) => {
        try {
            const myHeaders = new Headers();
            const formdata = new FormData();
            formdata.append("action", "getservicedescription");
            formdata.append("api_key", "test");
            formdata.append("category", formData.organisationCategory);
            formdata.append("subcategory", formData.organisationSubcategory);
            formdata.append("service_name", serviceName);
            const requestOptions = {
                method: "POST",
                body: formdata,
                redirect: "follow"
            };
            const response = await fetch("https://billing.kreatebiz.com/includes/sitegenerationapi.php", requestOptions);
            const result = await response.json();
    
            // Return the description
            return result?.description;
        } catch (error) {
            console.log('Error: ' + error.message);
        }
    };

    // Handler to generate description for a specific service
    const handleGenerateDescription = async (index) => {
        const serviceName = services[index].name;
        if (serviceName) {
            // Set loading to true
            updateServiceFields(index, { loading: true });
            const generatedDescription = await generateDescription(serviceName);
            updateServiceFields(index, { description: generatedDescription, loading: false });
        } else {
            alert('Please enter a service name first');
        }
    };

    return (
        <div className="ServiceRepeater">
            <h2>Service Repeater</h2>
            {services.map((service, index) => (
                <div key={index} className="service-item">
                    <input
                        type="text"
                        placeholder="Service Name"
                        value={service.name}
                        onChange={(e) => updateServiceFields(index, { name: e.target.value })}
                    />
                    <textarea
                        placeholder="Service Description"
                        value={service.description}
                        onChange={(e) => updateServiceFields(index, { description: e.target.value })}
                        rows="10"
                    />
                    <button
                        onClick={() => handleGenerateDescription(index)}
                        className="generate-description"
                        disabled={service.loading}
                    >
                        {service.loading ? 'Generating...' : 'Generate Description'}
                    </button>
                    <button
                        onClick={() => removeService(index)}
                        className="remove"
                    >
                        Remove
                    </button>
                </div>
            ))}
            <button onClick={addService} className="add-service">
                Add New Service
            </button>
            <button onClick={onBack} className="add-service">
               Back
            </button>
            <button onClick={handleService} className="add-service">
              Next
            </button>
        </div>
    );
};

export default Service;
